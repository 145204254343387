<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-cascader :options="treeData" :show-all-levels="false" :props="cascaderProps" v-model="cascaderValue" placeholder="请选择省市区" clearable class="inputs"></el-cascader>
				<el-select v-model="franchiseeId" placeholder="请选择加盟商" clearable class="inputs">
						<el-option :label="item.name" :value="item.id" v-for="(item, i) in franchiseeList" :key="i">
					</el-option>
				</el-select>
				<el-select v-model="state" placeholder="请选择状态" clearable class="inputs">
					<el-option label="运营中" :value="true"> </el-option>
					<el-option label="已撤机" :value="false"> </el-option>
				</el-select>
				<el-input v-model="inputValue" placeholder="请输入点位名称" clearable class="inputs"></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
			<div class="m_tools_content">
				<el-button @click="exportData" class="m_add m_btn_export">导出</el-button>
				<el-button @click="changeModel('map')" class="m_add m_btn_common" v-if="contentModel === 'table'">地图模式</el-button>
				<el-button @click="changeModel('table')" class="m_add m_btn_common" v-else>表格模式</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content" v-show="contentModel === 'table'">
				<el-table :data="tableData" stripe v-loading="loading">
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="province" label="省" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="city" label="市" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="county" label="区/县" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="addr" label="详细地址" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="name" label="点位名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="合伙人" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.operator.franchisee.name }}
						</template>
					</el-table-column>
					<el-table-column label="加盟商" show-overflow-tooltip>
						<template slot-scope="scope">
							{{ scope.row.operator.name }}
						</template>
					</el-table-column>
					<el-table-column prop="machineCount" label="设备数量" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="状态" show-overflow-tooltip>
						<template slot-scope="scope">
							<i v-if="scope.row.status === 'Valid'" style="color: #62c462">运营中</i>
             				<i v-else style="color: #ff4343">已撤机</i>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="m_map_content" v-show="contentModel === 'map'" id="m_map"></div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total" v-if="contentModel === 'table'"></m-pagination>
		</div>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE,XHR_URL } from "@/utils/config";
	import { regions } from "@/api/base";
	import {
		locationsSelect,
		locationsInsert,
		locationsUpdate,
		locationsDelete,
		locationsExport
	} from "@/api/areaPoint";
	import { operatorsSelect } from "@/api/franchisee";
	import regulars from "@/utils/regular";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			const _this = this;
			return {
				loading:false,
				loc:require('../../assets/image/location.png'),
				total: 0,
				currentPage: 1,
				inputValue: null,
				cascaderValue: "",
				franchiseeId: null,
				state: null,
				treeData: [],
				cascaderProps: {
					lazy: true,
					checkStrictly: true,
					async lazyLoad(node, resolve) {
						const result = await _this.lazyLoad(node);
						resolve(result);
					},
				},
				tableData: [],
				contentModel: "table",
				map: null,
				franchiseeList:[],
				mapPoints:[],
				center: {
			        lng:116.40, 
			        lat:39.90
			      },
			      locationIcon: {
			        url:require('../../assets/image/location.png'),
			        size: {width: 40, height: 40}
			      },
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl(){
				return XHR_URL;
			}
		},
		components: {
			mPagination,
		},
		async created() {
			this.select();
			this.operatorsSelect();
			
			const res = await this.regions();
			this.treeData = res;
		},
		mounted() {
//			this.initMap();
		},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.loading=true;
				this.select();
			},
			exportData() {
				const queryData = {
					id:this.franchiseeId && String(this.franchiseeId).length > 0 ?
						this.franchiseeId : null,
					province: this.cascaderValue && this.cascaderValue[0] ? this.cascaderValue[0] : null,
					city: this.cascaderValue && this.cascaderValue[1] ? this.cascaderValue[1] : null,
					county: this.cascaderValue && this.cascaderValue[2] ? this.cascaderValue[2] : null,
					running:this.state && String(this.state).length > 0 ?
						this.state : null,
					name: this.inputValue && String(this.inputValue).length > 0 ?
						this.inputValue : null,
				};
				this.exportFile(queryData);
			},
			changeModel(model) {
				this.contentModel = model;
				if(this.contentModel=='map'){
					this.initMap();
				}
			},
			initMap() {
				setTimeout(()=>{
					this.creatMap();
					this.addMapControl();
					console.log(this.mapPoints);
					this.addAround(this.mapPoints);
				},500)
				
			},
			creatMap(){
				this.map = new BMap.Map("m_map");
				let point = new BMap.Point(this.center.lng, this.center.lat);
				this.map.centerAndZoom(point,10);
				this.map.enableScrollWheelZoom();
			},
			// 添加控件
		    addMapControl(){
		      //向地图中添加缩放控件
		      var ctrl_nav = new BMap.NavigationControl({anchor:BMAP_ANCHOR_BOTTOM_RIGHT,type:BMAP_NAVIGATION_CONTROL_LARGE});
		      this.map.addControl(ctrl_nav);
		      // 创建当前用户标注
		      var ctrl_marker = new BMap.Point(this.center.lng,this.center.lat)
		      var myIcon = new BMap.Icon(this.loc,new BMap.Size(25,25))
		      var marker_one = new BMap.Marker(ctrl_marker,{icon:myIcon})
		      this.map.addOverlay(marker_one)
		      
//		      // 创建一个圆
		      var circle = new BMap.Circle(ctrl_marker,500,{strokeColor:"#f3c6b1",fillColor:"#f3c6b1", strokeWeight:1, strokeOpacity:0.5})
		      this.map.addOverlay(circle); 
		      this.map.clearOverlays();
		    },
		    //  加载附近的人信息
		    addAround: function(markerList) {
		    	console.log('markerList',markerList)
		    	var markers = [];
		      if(markerList.length>0){
		        for(var i=0;i<markerList.length;i++){
		          // 经度
		          var info1 = markerList[i].point.split(',')[0] 
		          // 纬度
		          var info2 = markerList[i].point.split(',')[1]
		          // 绘制附近人点坐标
//		          var pointA = new BMap.Point(this.center.lng,this.center.lat)
		          var point = new BMap.Point(info1,info2)
		          // 距离
//		          var path = this.map.getDistance(pointA, point).toFixed(2)
		          var myIcon = new BMap.Icon(markerList[i].atlas,new BMap.Size(25,25))
		          var marker = new BMap.Marker(point,{icon:myIcon})
		           markers.push(marker);
		          this.map.addOverlay(marker)
		          //悬浮提示信息
		          var content = { 
		            name:markerList[i].name,
		            atlas: markerList[i].atlas,
		            id: markerList[i].id,
		            vmimg:markerList[i].vmimg,
//		            distance: path
		          }
		          
		          this.map.addOverlay(marker);
		          this.addClickHandler(content,marker)
		        }
		        
		        var myStyles = [{
		            url: require('../../assets/image/juloc.png'),
		            size: new BMap.Size(30, 30),
		            opt_anchor: [16, 0],
		            textColor: '#000',
		            opt_textSize: 10
		        }];
				var markerClusterer = new BMapLib.MarkerClusterer(this.map, {markers:markers});
			  	markerClusterer.setStyles(myStyles);
		      } else{
		        return 
		      }
		    },
		     //点击提示信息
		    addClickHandler(content,marker){
		      var _this = this;
		      
//		      <p style="font-size: 20px;color: #999;margin-top: 1.3333vw;">距你${content.distance}m</p>
		      var sContent = `<div id="ssst" style="display: flex;flex-direction: row;align-items: center;width: 40.3333vw;">
		          <img src="${content.vmimg}" alt="" style="width: 100px;height: 100px;margin-right: 2.2667vw;">
		          <div>
		            <p style="font-size: 24px;">${content.name}</p>
		          </div>
		          </div>`
		      var id = content.id
		      var infoWindow = new BMap.InfoWindow(sContent)
		      marker.addEventListener("click",function(e){
		        e.preventDefault = true
		        e.stop;
		        this.openInfoWindow(infoWindow)
		        setTimeout(() => {
		          e.preventDefault = true
		          e.stop;
		        }, 0);
		      })
		      
		    },
			async select() {
				const res = await locationsSelect({
					id:this.franchiseeId,
					province:this.cascaderValue[0],
					city:this.cascaderValue[1],
					county:this.cascaderValue[2],
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					running:this.state,
					name:this.inputValue
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
				let mapPoints=[];
				for(var i = 0;i< this.tableData.length;i++){
					let item = this.tableData[i];
					if(item.coord != null){
						mapPoints.push({
							id:item.id,
							point:item.coord,
							name:item.name,
          					atlas: this.loc,
          					vmimg:require('../../assets/image/onLine.png')
						});
					}
				}
				console.log(mapPoints);
				this.mapPoints = mapPoints;
				this.loading=false;
			},
			async exportFile(queryData) {
				queryData = queryData ? queryData : {};
				const res = await locationsExport(queryData);
				const {
					pageCount,
					results
				} = res;
				const a = document.createElement("a");
				const e = document.createEvent("MouseEvents");
				e.initEvent("click", false, false);
				a.href = this.fileUrl + results[0].url;
				a.download = results[0].fileName;
				a.dispatchEvent(e);
			},
			async operatorsSelect() {
				const res = await operatorsSelect({
					pageNo: 1,
					pageSize: 999999
				});
				const {
					pageCount,
					results
				} = res;
				this.franchiseeList = results;
			},
			async regions(data, level) {
				const res = await regions(data);
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push({
						value: item,
						label: item,
						leaf: level === 2,
					});
				});
				return list;
			},
			async lazyLoad(node) {
				const {
					level
				} = node;
				let query = null;
				switch(level) {
					case 1:
						query = {
							province: node.path[0],
						};
						break;
					case 2:
						query = {
							province: node.path[0],
							city: node.path[1],
						};
						break;
				}
				if(!query) return;
				const res = await this.regions(query, level);
				return res;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
	.inputs {
		width: 15%;
	}
	 .map-wrap /deep/ .anchorBL{
	    display: none;
	  } 
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>